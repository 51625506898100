<template>
  <v-dialog v-model="dialog" scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style="z-index=999999999"
        v-model="model.vmodel"
        :label="model.text"
        :rules="[rules.conform]"
        v-bind="attrs"
        v-on="on"
        outlined
        :disabled="disabled"
        :prepend-inner-icon="prependIcon"
      ></v-text-field>
    </template>
    <v-card>
      <v-card-title>{{ model.text }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                :autofocus="true"
                ref="inputarea"
                v-model="model.vmodel"
                :label="model.text"
                :hint="model.hint"
                :rules="[rules.conform]"
                outlined
                clearable
              ></v-text-field>
              <div class="text-center">
                <v-btn color="default" @click="setRingMissing"
                  >Ring missing</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-large @click="close">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DataInputDialogRingAcrylic',
  props: {
    data: Object,
    disabled: Boolean,
    prependIcon: String
  },
  data() {
    return {
      dialog: false,
      model: this.data,
      rules: {
        conform: value => {
          if (
            this.model.vmodel === '' ||
            this.model.vmodel === null ||
            this.model.vmodel === 'Ring missing'
          ) {
            return true
          }
          const pattern = /^[G]\d\d\d$/gi
          return (
            pattern.test(value) || 'Not a valid Acrylic ID, should be ´G###´'
          )
        }
      }
    }
  },
  methods: {
    close() {
      if (this.model.vmodel) {
        this.model.vmodel = this.model.vmodel.toUpperCase()
      }
      this.dialog = false
    },
    setRingMissing() {
      this.model.vmodel = 'Ring missing'
    }
  },
  mounted() {
    try {
      this.$refs.inputarea.$el.focus()
    } catch (err) {
      console.log()
    }
  }
}
</script>

<style scoped></style>
