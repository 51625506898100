<template>
  <div class="w-100 mt-4">
    <v-btn color="primary" block text @click="getDateTime"
      >Set date/time now</v-btn
    >
    <div class="d-flex mt-4">
      <v-text-field
        class="mt-0 mr-2 input"
        v-model="fields.date.vmodel"
        label="Date"
        outlined
        clearable
        :rules="[rules.dateFormat]"
      ></v-text-field>
      <v-text-field
        class="mt-0 input"
        v-model="fields.time.vmodel"
        label="Time"
        outlined
        clearable
        :rules="[rules.timeFormat]"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateTimeInput',
  props: ['fields'],
  data() {
    return {
      rules: {
        dateFormat: val => {
          if (!val) {
            return true
          }
          const pattern = /^\d{4}-\d{2}-\d{2}$/gi
          return pattern.test(val) || 'Date format should be YYYY-MM-DD'
        },
        timeFormat: val => {
          if (!val) {
            return true
          }
          const pattern = /^\d{2}:\d{2}$/gi
          return pattern.test(val) || 'Time format should be HH:MM'
        }
      }
    }
  },
  methods: {
    getDateTime() {
      this.fields.date.vmodel = new Date().toISOString().split('T')[0]
      this.fields.time.vmodel = new Date().toLocaleTimeString().substring(0, 5)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  font-size: 0.8rem;
}
</style>
