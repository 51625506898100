<template>
  <v-dialog v-model="dialog" scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        style="z-index=999999999"
        v-model="model.vmodel"
        :label="model.text"
        v-bind="attrs"
        v-on="on"
        outlined
        :rules="[rules.minmax]"
        :disabled="disabled"
        :prepend-inner-icon="prependIcon"
      ></v-text-field>
    </template>
    <v-card>
      <v-card-title>{{ model.text }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                :autofocus="true"
                ref="inputarea"
                v-model="model.vmodel"
                :label="model.text"
                :hint="model.hint"
                :rules="[rules.minmax]"
                outlined
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-large @click="dialog = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DataInputDialog',
  props: {
    data: Object,
    disabled: Boolean,
    prependIcon: String,
    min: Number,
    max: Number
  },
  data() {
    return {
      dialog: false,
      model: this.data,
      rules: {
        minmax: val => {
          if (!this.min && !this.max) {
            return true // No checking needed
          }
          if (!val) {
            return true // No checking needed
          } else {
            return (
              (val >= this.min && val <= this.max) ||
              `Value usually between ${this.min} and ${this.max}`
            )
          }
        }
      }
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      try {
        this.$refs.inputarea.$el.focus()
      } catch (err) {
        console.log()
      }
    })
  },
  methods: {}
}
</script>

<style scoped></style>
