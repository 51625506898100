<template>
  <div>
    <priorBirdDataDialog
      :show="showPriorData"
      :fields="fields"
      @close="showPriorData = false"
    />

    <v-card class="mx-auto mb-5" color="#2A3B4D">
      <v-card-text>
        <v-alert v-if="deadBird" dense type="error">
          <span class="body-2">This bird is dead according to prior data.</span>
          <p class="body-2 mt-2 text-decoration-underline">
            Found dead: {{ deadBird.doc.date }}
          </p>
        </v-alert>
        <p class="caption white--text mb-0">Acrylic ID</p>
        <p v-if="!acrylicIdSuggestion" class="text-h5 white--text">
          {{ fields.acrylic.vmodel }}
        </p>
        <p v-if="acrylicIdSuggestion" class="text-h5 white--text mb-0">
          <span class="o-5"
            >{{ acrylicIdSuggestion }}?
            <v-btn @click="fillAcrylicSuggestion">Fill</v-btn></span
          >
        </p>
        <p v-if="acrylicIdSuggestion" class="caption white--text">
          <span class="o-5"
            >Based on prior data, is this the correct Acrylic Id?</span
          >
        </p>

        <p class="caption white--text mb-0">Bto ID</p>
        <p v-if="!btoIdSuggestion" class="text-h5 white--text">
          {{ fields.bto.vmodel }}
        </p>
        <p v-if="btoIdSuggestion" class="text-h5 white--text mb-0">
          <span class="o-5"
            >{{ btoIdSuggestion }}?
            <v-btn @click="fillBtoSuggestion">Fill</v-btn></span
          >
        </p>
        <p v-if="btoIdSuggestion" class="caption white--text">
          <span class="o-5"
            >Based on prior data, is this the correct Bto Id?</span
          >
        </p>

        <div
          class="white--text my-1"
          v-if="fields.lat.vmodel && fields.lng.vmodel"
        >
          <div>
            <v-icon class="white--text">mdi-map-marker</v-icon>Lat:
            {{ fields.lat.vmodel }}
          </div>
          <div>
            <v-icon class="white--text">mdi-map-marker</v-icon>Lng:
            {{ fields.lng.vmodel }}
          </div>

          <v-btn large block class="my-5" @click="$emit('setLocation')"
            >Set loc. to Grassholm</v-btn
          >

          <div v-if="isOnline">
            <v-icon class="white--text mr-1">mdi-earth</v-icon>
            <a
              target="_blank"
              :href="
                'http://www.google.com/maps/place/' +
                  fields.lat.vmodel +
                  ',' +
                  fields.lng.vmodel
              "
              >See location on a map</a
            >
          </div>
        </div>
        <div>
          <v-btn
            @click="showPriorData = true"
            v-show="possibleToShow"
            color="#2A3B4D"
            block
            text
            large
            class="white--text"
            >See prior data<br />
            about bird</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import Pouch from '@/store/Pouch.js'
import priorBirdDataDialog from '@/components/priorBirdDataDialog.vue'

export default {
  name: 'BirdInfoPanel',
  props: ['fields'],
  data() {
    return {
      deadBird: false,
      acrylicIdSuggestion: null,
      btoIdSuggestion: null,
      showPriorData: false,
      birdInfo: {
        lastCapture: null,
        firstCapture: null,
        totalCapturesCount: null,

        lastObservation: null,
        firstObservation: null,
        totalObservationCount: null
      }
    }
  },
  computed: {
    possibleToShow() {
      let canShow = false
      if (this.fields.bto.vmodel) {
        canShow = true
      } else if (this.fields.acrylic.vmodel) {
        canShow = true
      }
      return canShow
    }
  },
  watch: {
    'fields.bto': {
      handler: 'btoIdHandler',
      deep: true
    },
    'fields.acrylic': {
      handler: 'acrylicIdHandler',
      deep: true
    }
  },
  methods: {
    fillAcrylicSuggestion() {
      this.fields.acrylic.vmodel = this.acrylicIdSuggestion
      this.acrylicIdSuggestion = null
    },
    fillBtoSuggestion() {
      this.fields.bto.vmodel = this.btoIdSuggestion
      this.btoIdSuggestion = null
    },
    async btoIdHandler(valAfter, valBefore) {
      this.acrylicIdSuggestion = null
      let res = await this.findCapturesByBto(valAfter.vmodel)
      if (res.length) {
        this.suggestAcrylic(res)
      }
    },
    async acrylicIdHandler(valAfter, valBefore) {
      this.btoIdSuggestion = null
      let res = await this.findCapturesByAcrylic(valAfter.vmodel)
      if (res.length) {
        this.suggestBto(res)
      }
    },
    suggestBto(data) {
      if (data[0].doc.bto !== this.fields.bto.vmodel) {
        this.btoIdSuggestion = data[0].doc.bto
      }
    },
    suggestAcrylic(data) {
      if (data[0].doc.acrylic !== this.fields.acrylic.vmodel) {
        this.acrylicIdSuggestion = data[0].doc.acrylic
      }
    },
    checkIfDead(data) {
      this.deadBird = data.find(row => row.doc.dead === true)
    },
    async findCapturesByBto(btoId) {
      //gets back a sorted array
      const selector = { type: 'capture', key: 'bto', value: btoId }
      let res = await Pouch.find(selector)
      this.checkIfDead(res)
      return res
    },
    async findCapturesByAcrylic(acrylicId) {
      //gets back a sorted array
      const selector = { type: 'capture', key: 'acrylic', value: acrylicId }
      let res = await Pouch.find(selector)
      this.checkIfDead(res)
      return res
    }
  },
  components: {
    priorBirdDataDialog
  }
}
</script>

<style scoped>
.white--text {
  color: #fff !important;
  font-size: 1rem;
}
.o-5 {
  opacity: 0.5;
}
.bird-info {
  display: block;
}
</style>
