/* eslint-disable no-unused-vars */
/* jshint esversion: 8 */

import Pouch from '@/store/Pouch.js'
import _flatten from 'lodash/flatten'
import _uniq from 'lodash/uniq'
import _uniqBy from 'lodash/uniqBy'
var _sortBy = require('lodash/sortBy')

const getPriorBirdData = (btoId, acrylicId) => {
  return new Promise((resolve, reject) => {
    let arrCaptures
    let arrObserves
    let capturesBto = findBirds('capture', 'bto', btoId)
    let capturesAcrylic = findBirds('capture', 'acrylic', acrylicId)
    let observesBto = findBirds('observe', 'bto', btoId)
    let observesAcrylic = findBirds('observe', 'acrylic', acrylicId)
    Promise.all([
      capturesBto,
      capturesAcrylic,
      observesBto,
      observesAcrylic
    ]).then(values => {
      arrCaptures = values.slice(0, 2)
      arrCaptures = _flatten(arrCaptures)
      arrCaptures = _uniqBy(arrCaptures, 'id')
      arrCaptures = sort(arrCaptures)

      arrObserves = values.slice(-2)
      arrObserves = _flatten(arrObserves)
      arrObserves = _uniqBy(arrObserves, 'id')
      arrObserves = sort(arrObserves)

      resolve([arrCaptures, arrObserves])
    })
  })
}

const findBirds = async (type, key, id) => {
  //gets back a sorted array
  const selector = { type: type, key: key, value: id }
  let res = await Pouch.find(selector)
  return res
}
const sort = data => {
  let d
  let result = _sortBy(data, o => {
    try { 
      d = new Date(o.doc.date + ' ' + o.doc.time).toISOString()
    }
    catch (e) {
      d = o.doc.date + ' ' + o.doc.time
    }
    return d
  })
  return result.reverse()
}
export { getPriorBirdData }
