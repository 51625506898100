<template>
  <v-dialog v-model="show" persistent height="400" width="600">
    <v-card>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="headline">
            Prior data
          </v-list-item-title>
          <v-list-item-subtitle>{{ fields.bto.vmodel }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{
            fields.acrylic.vmodel
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-text class="mx-0 px-0 ">
        <figure class="px-5">
          <figcaption class="text-subtitle-2" v-if="captures">
            Prior captures ({{ captures.length }})
          </figcaption>
          <figcaption class="text-subtitle-2" v-else>
            Prior captures - No data available
          </figcaption>
          <div v-if="captures.length > 0">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Date/time
                    </th>
                    <th class="text-left">
                      Scribe
                    </th>
                    <th class="text-left">
                      Tarsus
                    </th>
                    <th class="text-left">
                      Bill
                    </th>
                    <th class="text-left">
                      Wing
                    </th>
                    <th class="text-left">
                      Net weight
                    </th>
                    <th class="text-left">
                      Sex
                    </th>
                    <th class="text-left">
                      Location
                    </th>
                    <th class="text-left">
                      Partner bird
                    </th>
                    <th class="text-left">
                      Loggers
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="c in captures" :key="c._id">
                    <td class="text-caption">
                      {{ c.doc.date + ' ' + c.doc.time }}
                    </td>
                    <td class="text-caption">{{ c.doc.scribe }}</td>
                    <td class="text-caption">{{ c.doc.tarsus }}</td>
                    <td class="text-caption">{{ c.doc.bill }}</td>
                    <td class="text-caption">{{ c.doc.wing }}</td>
                    <td class="text-caption">
                      {{ c.doc.gross_weight - c.doc.bag_weight }}
                    </td>
                    <td class="text-caption">{{ c.doc.sex }}</td>
                    <td class="text-caption">{{ getLocation(c.doc) }}</td>
                    <td class="text-caption">{{ c.doc.partner_bird }}</td>
                    <td class="text-caption">{{ getLoggers(c.doc) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </figure>
        <figure class="px-5 mt-5">
          <figcaption class="text-subtitle-2" v-if="observes">
            Prior observations ({{ observes.length }})
          </figcaption>
          <figcaption class="text-subtitle-2" v-else>
            Prior observations - No data available
          </figcaption>
          <div v-if="observes.length > 0">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Date/time
                    </th>
                    <th class="text-left">
                      Scribe
                    </th>
                    <th class="text-left">
                      Location
                    </th>
                    <th class="text-left">
                      Breeder
                    </th>
                    <th class="text-left">
                      Comment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="c in observes" :key="c._id">
                    <td class="text-caption">
                      {{ c.doc.date + ' ' + c.doc.time }}
                    </td>
                    <td class="text-caption">{{ c.doc.scribe }}</td>
                    <td class="text-caption">{{ getLocation(c.doc) }}</td>
                    <td class="text-caption">{{ c.doc.breeder }}</td>
                    <td class="text-caption">{{ c.doc.comments }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </figure>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-large @click="$emit('close')">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable no-unused-vars */
import { getPriorBirdData } from '@/lib/priorDataOnBird.js'
export default {
  props: ['show', 'fields'],
  data() {
    return {
      captures: [],
      observes: [],
      presentable: false
    }
  },
  unmounted() {
    this.presentable = false
  },
  methods: {
    getLocation(row) {
      console.log(row)
      if (row.location === 'Other') {
        return row.other_location
      } else {
        return row.location
      }
    },
    getLoggers(row) {
      let loggers = []
      if (row.loggers.length == 0) {
        loggers = ['No loggers']
      } else {
        row.loggers.forEach(l => {
          loggers.push(l.number)
        })
      }
      return loggers.join(', ')
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          getPriorBirdData(
            this.fields.bto.vmodel,
            this.fields.acrylic.vmodel
          ).then(data => {
            this.captures = data[0]
            this.observes = data[1]
            this.presentable = true
          })
        }
      }
    }
  }
}
</script>

<style scoped>
li {
  border-bottom: 1px grey solid;
}
</style>
